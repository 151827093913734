<template>
  <div class="date-picker">
    <div class="dropdown__wrapper">
      <div class="dropdown__content" @click="showDropdown = !showDropdown">
        <span v-if="label">
          {{labelContent}}
        </span>
        <svg>
          <use v-bind="{'xlink:href':'#dropdown-icon-blue'}"></use>
        </svg>
      </div>
      <div v-if="showDropdown" class="dropdown__options">
        <button @click="getDetails(month)" v-for="month in getMonths" :key="month.key">
          {{month}}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';

export default {
  name: 'DatePicker',
  data() {
    return {
      showDropdown: false,
      showCustomOptions: false,
      chosenMonth: 'January',
      chosenYear: '2020',
      labelContent: '',
      currentYear: moment().year(),
    };
  },
  props: {
    activeMonth: {
      type: String,
    },
    projectCreatedDate: {
      type: Number,
    },
  },
  computed: {
    getMonths() {
      const start = moment.unix(this.projectCreatedDate).format('YYYY-MM-DD').split('-');
      const end = moment().format('YYYY-MM-DD').split('-');
      const startYear = parseInt(start[0], 10);
      const endYear = parseInt(end[0], 10);
      const totalMonths = [];

      for (let i = startYear; i <= endYear; i += 1) {
        const endMonth = i !== endYear ? 11 : parseInt(end[1], 10) - 1;
        const startMon = i === startYear ? parseInt(start[1], 10) - 1 : 0;
        for (let j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
          const month = j + 1;
          let displayMonth = month < 10 ? `0${month}` : month;
          displayMonth = moment(displayMonth, 'MM').format('MMMM');
          displayMonth = `${displayMonth} ${i}`;
          totalMonths.push(displayMonth);
        }
      }
      totalMonths.reverse();
      return totalMonths;
    },
    label() {
      /* eslint-disable vue/no-side-effects-in-computed-properties */
      if (this.$route.query && this.$route.query.month && this.$route.query.year) {
        const monthNumber = parseInt(this.$route.query.month, 10) - 1;
        const month = moment().month(monthNumber).format('MMMM');
        const year = moment(this.$route.query.year).format('YYYY');
        this.labelContent = `${month} ${year}`;
        return true;
      }
      this.labelContent = moment().format('MMMM YYYY');
      return true;
    },
  },
  methods: {
    getDetails(monthName) {
      const params = {};
      /* eslint-disable prefer-destructuring */
      const month = moment().month(monthName.split(' ')[0]).format('M');
      if (month <= 9) {
        params.month = 0 + month;
      } else {
        params.month = month;
      }
      params.year = monthName.split(' ')[1];
      if (this.$route.query && this.$route.query.month && this.$route.query.year) {
        params.changeMonth = true;
        this.$emit('monthchange', params);
      } else {
        this.$emit('monthchange', params);
      }
      this.labelContent = `${monthName}`;
      this.showDropdown = !this.showDropdown;
    },
  },
};
</script>
<style lang="scss" scoped>
.date-picker {
  display: inline-block;
  .dropdown__wrapper {
    width: size(220);
    .dropdown__content {
      cursor: pointer;
      @include font-styles(
        $type: 'display',
        $size: 'xs',
        $weight: 'bold'
      );
      position: relative;
      color: var(--global--common-link--Color);
      svg {
        width: size(18);
        height: size(11);
        cursor: pointer;
        fill: var(--global--common-link--Color);
        stroke: var(--global--common-link--Color);
        margin-left: size(8);
      }
    }
    .dropdown__options {
      position: absolute;
      left: size(120);
      top: size(35);
      padding: size(20);
      background: var(--global--white--Color);
      border-radius: size(6);
      width: size(220);
      max-height: size(300);
      overflow-y: auto;
      box-shadow: 0 size(1) size(3) rgba(0, 0, 0, 0.1),
        0 size(2) size(1) rgba(0, 0, 0, 0.06), 0 size(1) size(1) rgba(0, 0, 0, 0.08);
      @include font-styles(
        $type: 'label',
        $size: 'lg',
        $weight: 'regular'
      );
      svg {
        display: inline-block;
        margin-right: size(10);
        width: size(11);
        height: size(14);
      }
      button {
        display: block;
        outline: none;
        border: none;
        margin-bottom: size(15);
        cursor: pointer;
        width: 100%;
        text-align: left;
        color: var(--global--main--title--Color);
        &:last-child {
          margin-bottom: size(0);
        }
        &:hover {
          color: var(--global--common-link--Color);
          svg {
            fill: var(--global--common-link--Color);
          }
        }
        &.active {
          color: var(--global--common-link--Color);
          svg {
            fill: var(--global--common-link--Color);
          }
        }
      }
      select {
        border: none;
        background: none;
        width: size(180);
        margin-top: size(10);
        border-bottom: size(1) solid var(--global--supportive--Color);
        -webkit-appearance:none;
        -moz-appearance:none;
        appearance: none;
        background: url('../assets/dropdown-black.svg') no-repeat right;
        @include font-styles(
          $type: 'text',
          $size: 'lg',
          $weight: 'normal'
        );
        &:focus {
          outline: none;
        }
        &:first-child {
          margin-right: size(50);
        }
      }
    }
  }
}
</style>
