<template>
  <div class="api-usage">
    <div class="api-usage__table-wrapper">
      <table>
        <thead>
          <tr>
            <th>Products</th>
            <th>Price
            </th>
            <th>Quantity</th>
            <th>Line Total</th>
          </tr>
        </thead>
        <tbody v-if="activeProject && activeProject.usage && activeProject.usage.resource_wise_spending
        && Object.keys(activeProject.usage.resource_wise_spending).length > 0 && activeProject.usage.name !== 'Error'
         ">
          <template v-for="(data, name , index) in activeProject.usage.resource_wise_spending"
           :key="index">
            <tr v-if="index < maxLen" class="usage-details"
              :class="data.total < 0 ? 'add-color' : ''">
              <td class="usage-details__api-name">
                <span>{{data.name}}</span>
                <a v-if="data.docs_url" class="link" :href="resourceKey() + data.docs_url">
                  <svg>
                    <use v-bind="{'xlink:href':'#link-arrow-icon'}"></use>
                  </svg>
                </a>
                {{link}}
              </td>
              <td class="usage-details__unit-price">
                <span>{{formatCurrency(data.unit_price, activeProject.usage.currency, false, true)}}</span>
              </td>
              <td class="usage-details__quantity-info">
                <span>{{data.quantity}}</span>
              </td>
              <td>
                <span class="usage-details__price-info">
                  {{formatCurrency(data.total, activeProject.usage.currency, false, true)}}
                </span>
                <!-- <img src="@/assets/dropdown.svg" @click="expandIcon(data.key, index)"
                  :class="[expand === index ? 'inv-icon': 'expand-icon']"> -->
              </td>
            </tr>
            <tr class="usage-details__graph-info" v-if="expand === index">
              <td colspan="4">
                <div v-if="activeProject && activeProject.resource_usage
                && activeProject.resource_usage[data.key] &&
                Object.values(activeProject.resource_usage[data.key].data.day_wise_spending) &&
                activeProject.resource_usage[data.key].label">
                  <h3>Total spent</h3>
                  <span>{{formatCurrency(data.total, activeProject.usage.currency)}}</span>
                  <chart :chartType="'line'"
                    :dataArray="activeProject.resource_usage[data.key]
                    && activeProject.resource_usage[data.key].data
                    && Object.values(activeProject.resource_usage[data.key].data.day_wise_spending)"
                    :dataSets="chartDataSets"
                    :labels="activeProject.resource_usage[data.key]
                    && activeProject.resource_usage[data.key].label"
                    :borderColor="chart.borderColor"
                    :borderWidth="chart.borderWidth" :elementRef="'yourChart'"/>
                </div>
              </td>
            </tr>
          </template>
          <tr class="usage-details__total-amount">
            <td></td>
            <td></td>
            <td>Grand Total</td>
            <td>{{formatCurrency(activeProject.usage.total_spending, activeProject.usage.currency, false, true)}}</td>
          </tr>
        </tbody>
        <tbody
          class="empty-state"
          v-else
        >
          <tr>
            <td>
              <img src="@/assets/chessboard.svg" class="empty-state__image" alt="chess board with two coins"/>
              <p class="empty-state__text"> No Usage Yet! </p>
            </td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { currencyMethodsMixin } from '@/mixin/currency';
import Chart from '@/components/Charts/Chart.vue';
import Config from '@/config';

export default {
  name: 'usageBilling',
  components: {
    Chart,
  },
  mixins: [currencyMethodsMixin],
  computed: {
    ...mapState({
      activeProject: (state) => state.project.activeProject,
      countNumber() {
        if ((this.activeProject && this.activeProject.usage
        && this.activeProject.usage.resource_wise_spending
        && Object.keys(this.activeProject.usage.resource_wise_spending).length) > this.maxLen) {
          const count = Object.keys(this.activeProject.usage.resource_wise_spending).length - this.maxLen;
          return count;
        }
        return true;
      },
    }),
  },
  props: {
    maxLen: {
      type: Number,
      default: 10,
    },
    activeMonth: {
      type: String,
    },
  },
  data() {
    return {
      expand: false,
      id: Number,
      chart: {
        borderColor: '#F99CBE',
        borderWidth: '3',
        options: { responsive: true, maintainAspectRatio: true },
      },
      chartData: null,
      labelData: null,
      resourceLink: `${Config.sportsHost}`,
    };
  },
  methods: {
    expandIcon(key, i) {
      this.expand = this.expand === i ? null : i;
      const params = {};
      /* eslint-disable prefer-destructuring */
      params.month = this.activeMonth.split('-')[1];
      params.year = this.activeMonth.split('-')[0];
      params.resourceKey = key;
      params.projectKey = this.activeProject.key;
      if (!this.activeProject.resource_usage[key]) {
        this.$store.dispatch('project/fetchResourceUsage', { params }).then((resp) => resp).catch(() => {
          console.log('error');
        });
      }
    },
    resourceKey() {
      return this.resourceLink.replace(/(?:\/+(\?))/, '$1').replace(/\/+$/, '');
    },
  },
};
</script>
<style lang="scss" scoped>
.api-usage {
  max-width: size(320);
  margin: 0 auto;
  background: var(--global--white--Color);
  box-shadow: 0 size(1) size(3) rgba(0, 0, 0, 0.1), 0 size(2) size(1) rgba(0, 0, 0, 0.06),
    0 size(1) size(1) rgba(0, 0, 0, 0.08);
  border-radius: size(8);
  padding: size(30);
  overflow: hidden;
  margin-bottom: size(60);
  @media screen and (min-width: $breakpoint-md) {
    max-width: size(680);
    padding: size(30) size(32) size(60);
  }
  @media screen and (min-width: $breakpoint-lg) {
    max-width: size(1130);
  }
  table {
    width: 100%;
    thead {
      tr {
        th {
          color: var(--global--commom-title--Color);
          text-align: right;
          padding-bottom: size(12);
          border-bottom: size(1) solid var(--global--line--table--BackgroundColor);
          @include font-styles(
            $type: 'text',
            $size: 'md',
            $weight: 'regular'
          );
          &:first-child {
            width: size(170);
            text-align: left;
            @media screen and (min-width: $breakpoint-md) {
              width: size(320);
            }
            @media screen and (min-width: $breakpoint-lg) {
              width: size(580);
            }
          }
          &:last-child {
            width: size(80);
            @media screen and (min-width: $breakpoint-md) {
              width: size(130);
            }
          }
          svg {
            display: inline-block;
            margin-left: size(10);
            width: size(11);
            height: size(11);
          }
        }
      }
    }
    tr {
      td {
        padding: size(10) 0 size(5);
        border-bottom: size(1) solid var(--global--line--table--BackgroundColor);
        @include font-styles(
          $type: 'label',
          $size: 'lg',
          $weight: 'regular'
        );
        text-align: right;
        color: var(--global--common--title--Color);
        &:first-child {
          text-align: left;
        }
        &.usage-details__quantity-info {
          width: size(150);
        }
        &.usage-details__unit-price{
          width: size(150);
        }
        .usage-details__price-info {
          display: inline-block;
          width: size(180);
          @include font-styles(
            $type: 'label',
            $size: 'lg',
            $weight: 'bold'
          );
        }
        &:hover {
          .link {
            svg {
              display: inline-block;
            }
          }
        }
        .link {
          display: inline-block;
          svg {
            display: none;
            width: size(10);
            height: size(10);
            margin-left: size(15);
          }
        }
        & > img {
          display: inline-block;
          width: size(16);
          height: size(10);
          margin-left: size(10);
          cursor: pointer;
          vertical-align: top;
          margin-top: size(8);
          transition: transform 0.3s linear;
          @media screen and (min-width: $breakpoint-md) {
            margin-left: 0;
          }
          @media screen and (min-width: $breakpoint-lg) {
            margin-left: size(10);
          }
          &.inv-icon {
            margin-top: size(4);
            transform: rotateX(180deg);
          }
        }
      }
      &.add-color {
        td {
          color: #00A656;
        }
      }
      &.usage-details__extra-entries {
        td {
          height: size(40);
          &:last-child {
            text-align: right;
            padding-right: size(40);
          }
        }
      }
      &.usage-details__total-amount {
        td {
          @include font-styles(
            $type: 'label',
            $size: 'lg',
            $weight: 'bold'
          );
          &:nth-child(3) {
            width: size(110);
          }
        }
      }
      &.usage-details__graph-info {
        border-bottom: 0;
        td {
          padding: 0;
          & > div {
            border: size(1) solid var(--global--line--BackgroundColor);
            border-top: none;
            padding: size(20) 0;
            h3 {
              @include font-styles(
                $type: 'text',
                $size: 'md',
                $weight: 'regular'
              );
              padding-bottom: size(10);
              padding-left: size(48);
            }
            span {
              display: inline-block;
              padding-left: size(48);
              @include font-styles(
                $type: 'display',
                $size: 'md',
                $weight: 'bold'
              );
            }
          }
        }
      }
    }
  }
  .empty-state {
    height: size(250);
    td {
      float: right;
      padding-right: size(50);
      border: none;
    }
    &__image {
      display: block;
      height: size(110);
      width: size(110);
      padding-top: size(40);
      margin-left: auto;
      margin-right: auto;
    }
    &__text {
      text-align: center;
      cursor: alias;
      color: var(--global--main--title--Color);
      @include font-styles(
        $type: 'text',
        $size: 'lg',
        $weight: 'regular'
      );
      padding: size(20) 0;
    }
  }
}
</style>
<style lang="scss">
.api-usage {
  table {
    tbody {
      tr {
        &.usage-details__graph-info {
          .widget-box {
            width: 100%;
            height: size(150);
            border: none;
            .chart-area-wrapper {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
</style>
